import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
} from "vue-router";
import login from "../login.vue";
import systemIndex from "../systemIndex.vue";
import accounts from "../components/accounts.vue";
import events from "../components/events.vue";
import newEvents from "../components/newEvents.vue";
import personCenter from "../components/personCenter.vue";
import paySet from "../components/paySet.vue";
import index from "../components/index.vue";
import sms_set from "../components/sms_set";

const router = createRouter({
	routes: [{
			path: "/",
			component: login
		}, //默认进入login页面
		{
			path: "/admin",
			name: "admin",
			component: () => import("../admin.vue"),
			meta: {
				title: "主页",
				requireAuth: true,
			},
			children: [{
					path: "/index",
					component: index,
					name: "index",
				},

				{
					path: "/invoice_set",
					component:() => import("../components/invoice_set.vue"),
					name: "invoice_set",
				},
				{
					path: "/sms_set",
					component: sms_set,
					name: "sms_set",
				},

				{
					path: "/accounts",
					component: accounts,
					name: "accounts",
				},
				{
					path: "/personCenter",
					component: personCenter,
					name: "personCenter",
				},
				{
					path: "/events",
					component: events,
					name: "events",
				},
				{
					path: "/newEvents",
					component: newEvents,
					name: "newEvents",
				},
				{
					path: "/paySet",
					component: paySet,
					name: "paySet",
				},
			],
		},
	],
	history: createWebHashHistory(),
});
//引入nprogress
import NProgress from "nprogress"; // 进度条
import "nprogress/nprogress.css"; //这个样式必须引入
// 简单配置
NProgress.inc(0.2);
NProgress.configure({
	easing: "ease",
	speed: 500,
	showSpinner: false
});

// 当路由请求之前

router.beforeEach((to, from, next) => {
	// 每次切换页面时，调用进度条
	NProgress.start();
	// 这个一定要加，没有next()页面不会跳转的
	next();
});
// 当路由请求之后：关闭进度条
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done();
});
export default router;