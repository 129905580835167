<template>
	<div
		id="loadContent"
		:style="{
			overflow: 'hidden'
		}"
	>
		<div style="padding: 10px; width: 100%">
			<div>
				<div>
					<!-- <el-steps :space="200" :active="activeStep" simple>
            <el-step title="活动信息" :icon="Edit" />
            <el-step title="注册通道" :icon="UploadFilled" />
            <el-step title="门票" :icon="Picture" />
          </el-steps> -->
					<div
						:style="{
							marginTop: '10px',
							maxHeight: mainHeight,
							minHeight: mainHeight,
							overflow: 'auto'
						}"
					>
						<div
							style="
								text-align: center;

								padding: 5px;
								font-weight: bold;
								font-size: 20px;
								/* background: #fff; */
								border-bottom: 1px solid #f6f6f6;
							"
						>
							<span>{{ model_simple_cloud_huiyi.huiyi_name ? model_simple_cloud_huiyi.huiyi_name : '会议信息' }}</span>
						</div>
						<el-form
							v-if="activeStep == 1"
							style="width: 50%; margin-left: 25%"
							:model="model_simple_cloud_huiyi"
							:rules="formRules"
							ref="ruleForm"
							label-width="150px"
							size="large"
							:hide-required-asterisk="false"
							:class="formContent"
						>
							<el-row>
								<el-col>
									<el-form-item label="会议图片：" prop="huiyi_img">
										<el-upload
											list-type="picture-card"
											action="#"
											:auto-upload="false"
											:on-change="handleChange"
											:accept="fileType"
											:limit="1"
											style="margin-right: 10px;"
											v-model:file-list="fileList"
										>
											<!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
											<el-icon v-if="fileList && fileList.length <= 0"><Plus /></el-icon>
										</el-upload>
										<a-image
										v-if="model_simple_cloud_huiyi.huiyi_img && model_simple_cloud_huiyi.huiyi_img != 'null'"
										
											width="140px"
											height="140px"
											
											:src="model_simple_cloud_huiyi.huiyi_img"
										/>
										
												
										
										<!-- <el-upload
                 
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="handleChange"
                      :accept="fileType"
                    >
                      <el-icon><Plus /></el-icon>

                      <template #file="{ file }">
                        <div>
                          <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                          />
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-preview"
                              @click="handlePictureCardPreview(file)"
                            >
                              <el-icon><zoom-in /></el-icon>
                            </span>
                            <span
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file)"
                            >
                              <el-icon><Delete /></el-icon>
                            </span>
                          </span>
                        </div>
                      </template>
                    </el-upload> -->

										<!-- <el-upload
                  :show-file-list="false"
                  :file-list="fileList"
                  class="upload-demo"
                  :before-upload="handleBeforeUpload"
                  ref="upload"
                  :limit="limit"
                  :http-request="httpRequest"
                  :on-change="OnChange"
                  :auto-upload="false"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview"
                  drag
                  :multiple="true"
                  :accept="fileType"
                >
                  <el-icon><upload /></el-icon>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                  <template #tip>
                    <div class="el-upload__tip" v-if="showTip">
                      <template v-if="fileSize">
                        请上传大小不超过
                        <b style="color: #f56c6c">{{ fileSize }}MB</b>
                      </template>
                      <template v-if="fileType">
                        格式为 <b style="color: #f56c6c">{{ fileType }}</b>
                      </template>
                      的文件
                    </div></template
                  >
                </el-upload> -->

										<!-- 文件列表 -->
										<!-- <div class="file-list">
                  <span
                    >已经选择{{ fileList.length }}个文件，最多选择{{
                      limit
                    }}个文件</span
                  >
                  <el-row :gutter="10">
                    <el-tooltip
                      v-for="(file, index) in fileList"
                      :key="file.uid"
                      :content="file.name"
                      placement="bottom"
                      effect="dark"
                    >
                      <el-col :span="5">
                        <img
                          :src="file.imgUrl"
                          v-if="file.imgUrl"
                          style="width: 100%"
                          alt
                        />
                        <span class="file-name">
                          {{ file.name }}
                        </span>
                        <el-button
                          type="text"
                          icon="el-icon-delete"
                          @click="handleDelete(index)"
                          >删除</el-button
                        >
                      </el-col>
                    </el-tooltip>
                  </el-row>
                </div> -->
									</el-form-item>
								</el-col>
								

								<el-col>
									<el-form-item label="会议名称：" prop="huiyi_name">
										<el-input :size="size" v-model="model_simple_cloud_huiyi.huiyi_name" placeholder="会议名称将要展示在官网及各个页面" />
									</el-form-item>
								</el-col>

								<el-col>
									<el-form-item label="会议时间：" prop="bg_end_dtme">
										<!-- <el-date-picker
											:size="size"
											placeholder="会议时间"
											type="daterange"
											format="YYYY-MM-DD"
											value-format="YYYY-MM-DD"
											range-separator="到"
											start-placeholder="会议开始时间"
											end-placeholder="会议结束时间"
											:disabled-date="disabledDate"
											v-model="model_simple_cloud_huiyi.bg_end_dtme"
										></el-date-picker> -->

										<!-- <a-range-picker
										      :show-time="{ format: 'YYYY-MM-DD' }"
										      format="YYYY-MM-DD"
										      :placeholder="['开始时间', '结束时间']"
										      @change="onRangeChange"
										      @ok="onRangeOk"
										    /> -->
										<!-- <a-date-picker v-model:value="model_simple_cloud_huiyi.bg_end_dtme">
										      <template #dateRender="{ current }">
										        <div class="ant-picker-cell-inner" :style="getCurrentStyle(current)">
										          {{ current.date() }}
										        </div>
										      </template>
										    </a-date-picker> -->
										<a-range-picker value-format="YYYY-MM-DD" v-model:value="model_simple_cloud_huiyi.bg_end_dtme" format="YYYY-MM-DD" />
									</el-form-item>
								</el-col>

								<el-col>
									<el-form-item label="会议地址：" prop="addr">
										<el-cascader
											:size="size"
											separator="-"
											class="dateInput"
											:options="pcTextArr"
											placeholder="会议地址"
											v-model="model_simple_cloud_huiyi.addr"
											@change="modelAddress($event, 'addr')"
										/>
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="详细地址：" prop="addr_details">
										<el-input :size="size" v-model="model_simple_cloud_huiyi.addr_details" />
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="会议类型：" prop="type">
										<el-select placeholder="会议类型" :size="size" v-model="model_simple_cloud_huiyi.type">
											<el-option label="本地会议" value="本地会议"></el-option>
											<el-option label="远程会议" value="远程会议"></el-option>
											<el-option label="医学会议" value="医学会议"></el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="注册人数：" prop="regist_total">
										<el-row style="width: 100%" :gutter="4">
											<el-col :span="8">
												<el-input
													:size="size"
													type="number"
													v-model="model_simple_cloud_huiyi.regist_total"
													@blur="checkRegistTotal(model_simple_cloud_huiyi.regist_total)"
												/>
											</el-col>
											<el-col :span="16">
												<span style="color: red" v-if="!statisticInfo.registNum || statisticInfo.registNum == 0">没有注册量【详情请询问账户管理员】</span>
												<span v-else>单场注册人数不能超过{{ statisticInfo.registNum }}个</span>
											</el-col>
										</el-row>
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="举办方联系人：" prop="huiyi_user">
										<el-input :size="size" v-model="model_simple_cloud_huiyi.huiyi_user" placeholder="举办方联系人" />
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="举办方联系电话：" prop="mobile">
										<el-input :size="size" v-model="model_simple_cloud_huiyi.mobile" placeholder="举办方手机号" />
									</el-form-item>
								</el-col>
								<el-col>
									<el-form-item label="简介：" prop="info">
										<el-input type="textarea" :rows="2" v-model="model_simple_cloud_huiyi.info" placeholder="简介" />
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<div v-if="activeStep == 2">
							<el-form ref="ruleForm1" label-width="150px" style="width: 50%; margin-left: 25%">
								<!-- 直接创建注册通道 -->
								<el-form-item label="创建默认注册通道">
									<el-switch
										disabled
										active-text="是"
										inactive-text="否"
										active-color="#13ce66"
										inactive-color="#13ce66"
										:active-value="0"
										:inactive-value="1"
									></el-switch>
								</el-form-item>
								<el-form-item label="通道名称">
									<el-input :size="size" v-model="model_simple_cloud_huiyi.registChannel.channel_name"></el-input>
								</el-form-item>
								<el-form-item label="注册开始时间">
									<el-date-picker
										:size="size"
										format="YYYY-MM-DD"
										value-format="YYYY-MM-DD"
										type="date"
										v-model="model_simple_cloud_huiyi.registChannel.bg_regist_dtme"
									></el-date-picker>
								</el-form-item>

								<el-form-item label="注册结束时间">
									<el-date-picker
										:size="size"
										format="YYYY-MM-DD"
										value-format="YYYY-MM-DD"
										type="date"
										v-model="model_simple_cloud_huiyi.registChannel.end_regist_dtme"
									></el-date-picker>
								</el-form-item>
							</el-form>
						</div>
						<el-form label-width="150px" v-if="activeStep == 3" ref="ruleForm2" style="width: 50%; margin-left: 25%">
							<!-- 创建默认门票 -->
							<el-form-item label="创建默认门票">
								<el-switch
									disabled
									active-text="是"
									inactive-text="否"
									active-color="#13ce66"
									inactive-color="#13ce66"
									:active-value="0"
									:inactive-value="1"
								></el-switch>
							</el-form-item>
							<el-form-item
								label="
               门票名称
              "
							>
								<el-input :size="size" v-model="model_simple_cloud_huiyi.addmissionModel.channel_name" placeholder="请输入门票名称" />
							</el-form-item>
							<el-form-item label="是否收费">
								<el-switch
									v-model="model_simple_cloud_huiyi.addmissionModel.is_pay"
									active-text="是"
									inactive-text="否"
									active-color="#13ce66"
									inactive-color="#ff4949"
									active-value="是"
									inactive-value="否"
								></el-switch>
							</el-form-item>

							<el-form-item label="收费金额" v-if="model_simple_cloud_huiyi.addmissionModel.is_pay == '是'">
								<el-input
									:size="size"
									:min="0"
									placeholder="请输入门票金额"
									oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
									v-model="model_simple_cloud_huiyi.addmissionModel.pay_amount"
									type="number"
								/>
							</el-form-item>
							<el-form-item label="门票描述">
								<el-input placeholder="请输入门票描述" v-model="model_simple_cloud_huiyi.addmissionModel.item_desc" type="textarea" rows="5" />
							</el-form-item>

							<el-form-item label="门票数量">
								<!-- 不能输入负值-->
								<el-input
									:min="0"
									:size="size"
									placeholder="请输入门票数量"
									oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
									v-model="model_simple_cloud_huiyi.addmissionModel.item_quantity"
									type="number"
								/>
							</el-form-item>
							<el-form-item label="门票有效期">
								<el-date-picker
									:size="size"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									v-model="model_simple_cloud_huiyi.addmissionModel.item_period_validity"
									placeholder="选择日期时间"
									:disabled-date="disabledDate"
								></el-date-picker>
							</el-form-item>

							<!-- <el-form-item label="门票折扣">
                <el-input
                  :size="size"
                  v-model="
                    model_simple_cloud_huiyi.addmissionModel.item_discount
                  "
                  placeholder="请输入门票折扣"
                />
              </el-form-item> -->

							<!-- <el-form-item label="关联注册通道">
               

                <el-checkbox-group
                  v-model="
                    model_simple_cloud_huiyi.addmissionModel.channel_list
                  "
                >
                  <el-checkbox
                    v-for="item in registChannelList"
                    :key="item.id"
                    :label="item"
                    >{{ item.channel_name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item> -->
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div style="text-align: center">
			<a-space>
				<a-button :icon="h(CheckOutlined)" type="primary" @click="onSubmitForm" :size="size">提交</a-button>
				<a-button @click="gotoEvents" :size="size">
					返回

					<template #icon><CloseCircleOutlined /></template>
				</a-button>
			</a-space>
			<!-- <el-button
        icon="edit"
        type="primary"
        link
        text
        v-if="activeStep != 1"
        @click="lastStep"
        :size="size"
      >
        上一步
      </el-button>
      <el-button
        icon="edit"
        type="primary"
        link
        text
        v-if="activeStep != 3 && !huiyiCode"
        @click="nextStep"
        :size="size"
      >
        下一步
      </el-button> -->
		</div>
		<el-dialog v-model="dialogVisible">
			<div style="height: 400px">
				<img w-full :src="dialogImageUrl" alt="Preview Image" style="width: 100%; height: 100%" />
			</div>
		</el-dialog>
	</div>
</template>
<script setup>
import { h } from 'vue';
import { CheckOutlined } from '@ant-design/icons-vue';
</script>
<script>
import { provinceAndCityData, pcTextArr, regionData, pcaTextArr, codeToText } from 'element-china-area-data';
import { myMixin } from './../mixin.js';
const { errorMsgBox, successMsgBox, doConfirm, apiAll, withLoading } = myMixin();
export default {
	components: { provinceAndCityData, regionData },
	created() {
		this.getInit();
	},
	data() {
		return {
			pcTextArr,
			size: 'large',
			activeStep: 1,
			fileType: ['.jpg', '.png', '.JPG', '.PNG', '.gif'], //选文件时筛选
			fileList: [],
			options2: provinceAndCityData,
			addressData1: [],
			formRules: {
				huiyi_name: [
					{
						required: true,
						message: '请输入会议名称',
						trigger: 'blur'
					}
				],
				addr: [
					{
						required: true,
						message: '请输入会议地址',
						trigger: 'blur'
					}
				],
				bg_end_dtme: [
					{
						required: true,
						message: '请输入会议时间',
						trigger: 'blur'
					}
				],
				regist_total: [
					{
						required: true,
						message: '请输入注册人数',
						trigger: 'blur'
					}
				]
				// mobile: [
				//   //非空验证
				//   { required: true, message: "请输入手机号", trigger: "blur" },
				//   // 手机号格式
				//   {
				//     required: true,
				//     pattern:
				//       /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
				//     message: "请输入正确的手机号码",
				//     trigger: "blur",
				//   },
				// ],
			},
			model_simple_cloud_huiyi: { registChannel: {}, addmissionModel: {} },
			mainHeight: '',
			dialogImageUrl: '',
			dialogVisible: false,
			accountInfo: '',
			statisticInfo:{}
		};
	},
	created() {
		this.getInit();
	},
	methods: {
		//禁止勾选之前的时间
		disabledDate(time) {
			return time.getTime() < Date.now() - 8.64e7;
		},
		// lastStep() {
		//   if (this.activeStep == 2) {
		//     this.activeStep = 1;
		//   } else if (this.activeStep == 3) {
		//     this.activeStep = 2;
		//   }
		// },
		// nextStep() {
		//   if (this.activeStep == 1) {
		//     this.$refs.ruleForm1.validate((valid, fields) => {
		//       if (valid) {
		//         this.activeStep = 2;
		//       } else {
		//         alert(2);
		//         // 自动聚焦错误input框
		//         var isError = document.getElementsByClassName(
		//           "el-form-item__error"
		//         );
		//         setTimeout(() => {
		//           var isError = document.getElementsByClassName("is-error");
		//           isError[0].querySelector("input").focus();
		//         }, 1000);
		//         console.log("error submit!!");
		//         return false;
		//       }
		//     });
		//   } else if (this.activeStep == 2) {
		//     this.$refs.ruleForm2.validate((valid, fields) => {
		//       if (valid) {
		//         this.activeStep = 3;
		//       } else {
		//         alert(2);
		//         // 自动聚焦错误input框
		//         var isError = document.getElementsByClassName(
		//           "el-form-item__error"
		//         );
		//         setTimeout(() => {
		//           var isError = document.getElementsByClassName("is-error");
		//           isError[0].querySelector("input").focus();
		//         }, 1000);
		//         console.log("error submit!!");
		//         return false;
		//       }
		//     });
		//   }
		// },
		gotoEvents() {
			this.$router.push('/events');
		},
		onSubmitForm() {
			this.$refs.ruleForm.validate((valid, fields) => {
				if (valid) {
					//调用提交
					this.submit();
				} else {
					// 自动聚焦错误input框
					var isError = document.getElementsByClassName('el-form-item__error');
					setTimeout(() => {
						var isError = document.getElementsByClassName('is-error');
						isError[0].querySelector('input').focus();
					}, 1000);
					return false;
				}
			});
		},

		getInit() {
			if (window.localStorage.kaola_accountInfo) {
				this.accountInfo = JSON.parse(window.localStorage.kaola_accountInfo);
				this.statisticInfo =JSON.parse(window.localStorage.statisticInfo);
			} else {
				errorMsgBox('未登录！');
				return false;
			}
			//初始化获取初始数据
			//获取id，使用id进行获取默认活动进行编辑
			let huiyiCode = window.sessionStorage.getItem('huiyiCode');
			if (huiyiCode && huiyiCode != 0) {
				this.getEventData(huiyiCode);
			}
			this.mainHeight = window.innerHeight - 170 + 'px';
		},
		async getEventData(huiyiCode) {
			let data = {};
			let params = {};
			params.ephemeralParam = huiyiCode;
			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/getEventInfo'));
			if (res.code >= 0) {
				this.model_simple_cloud_huiyi = res.extra;
				this.model_simple_cloud_huiyi.registChannel = {};
				this.model_simple_cloud_huiyi.addmissionModel = {};
			} else {
				errorMsgBox(res.message);
			}
		},

		//限制文件上传格式
		handleChange(file) {
			const { uid, name, size } = file;
			//   文件类型限制
			const fileTypeFlag = /^.png|.jpg|.jpeg|.gif$/.test(name.substring(name.lastIndexOf('.')).toLowerCase());
			if (!fileTypeFlag) {
				this.errorMsgBox('文件类型只能是.png|.jpg|.jpeg|.gif');
				const selectFileList = this.fileList.filter((item) => {
					return item.uid != uid;
				});
				this.fileList = selectFileList;
				return;
			}
			//   文件大小进行校验
			if (size > 1024 * 1024 * 5) {
				this.errorMsgBox('文件大小不能超过5Mb');
				const selectFileList = this.fileList.filter((item) => {
					return item.uid != uid;
				});
				this.fileList = selectFileList;
				return;
			}
		},
		handleRemove(f) {
			this.fileList.some((element, index) => {
				if (element.name == f.name) {
					this.fileList.splice(index, 1);
				}
			});
		},

		checkRegistTotal(value) {
			// if (value) {
			//   value = value.replace(/[^\d]/g, "");
			// }

			if (value <= 0) {
				value = 0;
			}
			if (value > this.statisticInfo.registNum) {
				value = this.statisticInfo.registNum;
			}
			this.model_simple_cloud_huiyi.regist_total = value;
		},
		//file提交
		async submit() {
			const formData = new FormData();
			if (this.fileList && this.fileList.length > 0) {
				formData.append('huiyi_img', this.fileList[0].raw);
			}
			let params = {};
			params.ephemeralParam = this.model_simple_cloud_huiyi;
			formData.append('params', JSON.stringify(params));
			const res = await this.withLoading(this.formDataApi(formData, '/newHuiyiUpload'));
			if (res.code >= 0) {
				successMsgBox('保存成功！');
				//this.$router.push('events');
				//如果是新建会议 不如直接进入会议
				if (!this.model_simple_cloud_huiyi.id) {
					window.open(window.location.origin + '/eventManager?thirdCode=' + res.extra);
				}
			} else {
				errorMsgBox(res.message);
			}
		},
		handlePictureCardPreview(uploadFile) {
			this.dialogImageUrl = uploadFile.url;
			this.dialogVisible = true;
		},

		//省市区三级联动事件，直接赋值给明细
		modelAddress(value, name, v) {
			let result = _.join(value, '-');
			this.model_simple_cloud_huiyi.addr_details = result;
			// this.model_simple_cloud_huiyi[name] = value;
			// alert(value);
			// alert(codeToText[value[0]]);
			// let selectedOptions = value;
			// selectedOptions.map((item) => (name += CodeToText[item] + "")); //将省市区三个拼接一起
			// //运用了CodeToText把编码转成了中文
			// let province = CodeToText[selectedOptions[0]];
			// let county = CodeToText[selectedOptions[1]];
			// let area = CodeToText[selectedOptions[2]];
			// alert(province);
		}
		// modelAddress(arr, fieldName, fieldMappingName) {
		//   alert(CodeToText[3]);
		//   let result = _.join([CodeToText[arr[0]], CodeToText[arr[1]]], "-");

		//   this.model_simple_hotel[fieldMappingName] = result;
		// },
	}
};
</script>
<style lang="less" scoped></style>
