<!-- <template>
  <ElConfigProvider:locale="zhCN">
    <router-view></router-view>
  </ElConfigProvider>
</template> -->

<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
</script>

<script>
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default {
	name: 'App',
	setup() {
		return {
			locale: zhCn,
			locale1: zhCN
		};
	},
	components: {
		ElConfigProvider
	}
};
</script>

<style lang="less">
:root {
	--primary-color: #1677ff;
	--hover-color: #38a2ff;
	--grey-color:rgba(255, 255, 255, 0.65);
	--el-menu-item-height:45px !important;
}
body {
	margin: 0;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	color: #2c3e50;
	/* margin-top: 60px; */
}
.el-checkbox__input {
	font-weight: bold;
	// border: 1px solid grey;

	.el-checkbox.el-checkbox--large .el-checkbox__inner {
		height: 20px;
		width: 20px;
	}
}
::-webkit-scrollbar-thumb {
	background-color: #018ee8;
	height: 10px;
	outline-offset: -2px;
	// outline: 2px solid red;
	-webkit-border-radius: 4px;
	border: 2px solid #fff;
}
/*---鼠标点击滚动条显示样式--*/
::-webkit-scrollbar-thumb:hover {
	background-color: #fb4446;
	height: 10px;
	-webkit-border-radius: 4px;
}
// 外层轨道
::-webkit-scrollbar-track {
	background-color: #fff;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.22);
}
/*---滚动条大小--*/
::-webkit-scrollbar {
	width: 6px;
}
/*---滚动框背景样式--*/
::-webkit-scrollbar-track-piece {
	background-color: #fff;
	-webkit-border-radius: 0;
}
</style>
