// mixins.js
import axios from "./axios.js";
import qs from "qs";
import {createVNode} from "vue"
import {
	ElMessage,
	ElMessageBox
} from "element-plus";
import {
	message,
	NotificationPlacement,
	notification
} from "ant-design-vue";
import {
	Modal
} from "ant-design-vue";
import {
	ExclamationCircleOutlined
} from "@ant-design/icons-vue";
export const myMixin = () => {

	const errorMsgBox = function(notice) {
		message.error(notice);
	};
	const successMsgBox = function(notice) {
		if (!notice) {
			notice = "成功";
		}
		message.success(notice);
	};
	const doConfirm = (notice, callback) => {
		Modal.confirm({
			title: "操作确认",
			icon: createVNode(ExclamationCircleOutlined),
			content: notice,
			onOk() {
				callback();
			},
			onCancel() {
				successMsgBox("取消操作");
			},
		});
	};

	const el_errorMsgBox = (notice) => {
		if (!notice) {
			notice = "错误";
		}
		ElMessage({
			message: notice,
			type: "error",
		});
	};

	const el_successMsgBox = (notice) => {
		if (!notice) {
			notice = "成功";
		}
		ElMessage({
			message: notice,
			type: "success",
		});
	};
	const el_confirm = (notice, callback) => {
		// 显示消息提示框，并等待用户确认
		ElMessageBox.confirm(notice, "操作确认")
			.then(() => {
				callback();
			})
			.catch(() => {
				ElMessage({
					type: "info",
					message: "Delete canceled",
				});
			});
	};
	//form表单提交，参数不用转换，自动修改头
	const formDataApi = (data, api) => {
		return async () => {
			let res = null;
			try {
				res = await axios.post(api, data);
				res = res.data;
				return res;
			} catch (e) {
				// this.$message({
				//   message: e.message,
				//   type: "error",
				// });
				errorMsgBox(e.message);
				if (_.isFunction(error)) {
					await error(e);
				}
				return defaultValue;
			}
		};
	};
	const apiAll = (data, api, config = {}) => {
		//1、默认api
		if (!api) {
			api = window.http;
		}
		//2、上传地址时使用心得content-type

		if (api == "/newHuiyiUpload") {
			//文件上传头Content-Type
			axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
		} else {
			//默认头Content-Type
			axios.defaults.headers.post["Content-Type"] =
				"application/x-www-form-urlencoded";
		}
		//3、默认空参数
		if (!data) {
			const params = {};
			data.params = JSON.stringify(params);
		}
		let qsParam = qs.stringify(data);
		return async () => {
			const {
				method = "POST", error, defaultValue = null
			} = config;
			let res = null;
			try {
				if (method.toUpperCase() === "GET") {
					res = await axios.get(api, {
						params: qsParam,
					});
				} else {
					res = await axios.post(api, qsParam);
				}
				res = res.data;
				return res;
			} catch (e) {
				// this.$message({
				//   message: e.message,
				//   type: "error",
				// });
				errorMsgBox(e.message);
				if (_.isFunction(error)) {
					await error(e);
				}
				return defaultValue;
			}
		};
	};
	const withLoading = async (asyncFunc) => {
		let res = null;
		try {
			res = await asyncFunc();
		} catch (e) {
			console.error("WithLoading Error: ", e);
		}
		return res;
	};
	return {
		errorMsgBox,
		successMsgBox,
		doConfirm,
		formDataApi,
		apiAll,
		withLoading,
	};
};