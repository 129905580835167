<template>
	<div
		id="loadContent"
		:style="{
			minHeight: mainHeight,
			overflow: 'auto',
			width: '100%'
		}"
	>
		<div>
			<el-row style="margin: 15px">
				<a-button type="primary" :size="size" v-if="!accountInfo.parentAccountId || accountInfo.parentAccountId == 0" :icon="h(PlusOutlined)" @click="openEdit(null, 2)">
					新建子账户
				</a-button>
			</el-row>
			<a-table
				:loading="loadingData"
				:scroll="{ x: 0, y: tableMaxHeight }"
				@change="handleTableChange"
				:columns="columns"
				:data-source="dataList"
				bordered
				:pagination="pagination"
			>
				<template #bodyCell="{ column, text, record }">
					<template v-if="column.dataIndex === 'action'">
						<a-button style="margin-right: 10px" type="primary" @click="openEdit(record, 1)">
							<template #icon>
								<EditOutlined />
							</template>

							编辑
						</a-button>
						<a-button danger @click="openEdit(record, 0)" v-if="record.parentAccountId == 0">
							<template #icon>
								<DeleteOutlined />
							</template>
							修改密码
						</a-button>
					</template>

					<template v-else-if="column.dataIndex === 'companyLogo' || column.dataIndex === 'imgUrl'">
						<a-image :width="50" :src="text" />
					</template>
					<template v-else>{{ text }}</template>
				</template>
			</a-table>
			<!-- <el-table
				:data="dataList"
				:class="tableClassNames"
				ref="table"
				:size="tableSize"
				min-height="100"
				:border="false"
				:highlight-current-row="true"
				style="width: 100%"
				:showHeader="true"
				@selection-change="handleSelectionChange"
				:header-cell-style="{
					background: '#eef1f6',
					color: '#606266'
				}"
				row-key="id"
				default-expand-all
			>
				<el-table-column
					key="5734"
					:show-overflow-tooltip="true"
					align="center"
					look="5734"
					min-width="180px"
					column-key="5734"
					prop="account_name"
					width="auto"
					class="itemTrue"
					label="账户名"
				>
					<template v-slot="scope">
						<span>
							<span>{{ scope.row.accountName }}</span>
						</span>
					</template>
				</el-table-column>

				<el-table-column
					key="5735"
					:show-overflow-tooltip="true"
					align="center"
					look="5735"
					min-width="180px"
					column-key="5735"
					prop="mobile"
					width="auto"
					class="itemTrue"
					label="手机号"
				>
					<template v-slot="scope">
						<span>
							<span>{{ scope.row.mobile }}</span>
						</span>
					</template>
				</el-table-column>

				<el-table-column
					key="5737"
					:show-overflow-tooltip="true"
					align="center"
					look="5737"
					min-width="180px"
					column-key="5737"
					prop="company_name"
					width="auto"
					class="itemTrue"
					label="公司名称"
				>
					<template v-slot="scope">
						<span>
							<span>{{ scope.row.companyName }}</span>
						</span>
					</template>
				</el-table-column>

				<el-table-column
					key="5738"
					:show-overflow-tooltip="true"
					align="center"
					look="5738"
					min-width="180px"
					column-key="5738"
					prop="company_logo"
					width="auto"
					class="itemTrue"
					label="公司logo"
				>
					<template v-slot="scope">
						<span>
							<span>
								<img :src="scope.row.companyLogo" style="width: 40px" />
							</span>
						</span>
					</template>
				</el-table-column>

				<el-table-column
					key="5739"
					:show-overflow-tooltip="true"
					align="center"
					look="5739"
					min-width="180px"
					column-key="5739"
					prop="head_img"
					width="auto"
					class="itemTrue"
					label="头像"
				>
					<template v-slot="scope">
						<span>
							<span><img :src="scope.row.imgUrl" style="width: 40px" /></span>
						</span>
					</template>
				</el-table-column>

				<el-table-column width="280" align="center" label="操作" fixed="right" style="margin-top: 20px">
					<template v-slot="scope">
						<el-button
							v-if="scope.row.parentAccountId == 0"
							size="meduim"
							type="primary"
							text
							link
							style="margin-right: 5px"
							icon="Lock"
							@click="openEdit(scope.row, 0)"
						>
							修改密码
						</el-button>

						<el-button v-else size="meduim" type="primary" text link style="margin-right: 5px" icon="Lock" @click="openEdit(scope.row, 3)">重置密码</el-button>
						<el-button size="meduim" type="primary" text link style="margin-right: 5px" icon="edit" 
						@click="openEdit(scope.row, 1)">编辑信息</el-button>
					</template>
				</el-table-column>
			</el-table> -->
		</div>

		<div>
			<a-modal left top="5vh" @ok="saveAccount" v-model:open="accountDialogShow" width="40%" title="账户操作" :before-close="handleClose">
				<el-row style="text-align: center">
					<el-col :span="24">
						<el-form label-width="100px" :rules="rules">
							<el-form-item :prop="i.name" :label="i.title" :required="i.isNull" v-for="i in currentColumns" :key="i.name" error="必填" :show-message="false">
								<el-input
									v-if="i.type == 'mobile'"
									type="number"
									:size="size"
									oninput="if(value.length > 11) value=value.slice(0, 11)"
									:placeholder="i.desc"
									v-model="currentRow[i.name]"
								></el-input>

								<div v-else-if="i.type == 'upload'" style="display: flex">
									<el-upload
										action="#"
										limit="1"
										list-type="picture-card"
										:auto-upload="false"
										:on-change="handleChange"
										v-model:file-list="currentRow[i.fileListName]"
									>
										<el-icon><Plus /></el-icon>
										<template #file="{ file }">
											<div>
												<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
												<span class="el-upload-list__item-actions">
													<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
														<el-icon><zoom-in /></el-icon>
													</span>
													<span class="el-upload-list__item-delete" @click="handleRemove(file, currentRow[i.fileListName])">
														<el-icon><Delete /></el-icon>
													</span>
												</span>
											</div>
										</template>
									</el-upload>
									<div>
										<img
											style="width: 148px; height: 148px; margin: 0 0 15px 10px; vertical-align: middle"
											:src="currentRow[i.name]"
											v-if="currentRow[i.name]"
										/>
									</div>
								</div>

								<el-input v-else-if="i.type == 'password'" :size="size" type="password" :placeholder="i.desc" v-model="currentRow[i.name]"></el-input>
								<el-input v-else :size="size" :placeholder="i.desc" v-model="currentRow[i.name]"></el-input>
							</el-form-item>

							<!-- <el-form-item label="">
								<el-button icon="CircleCheck" :size="size" type="primary" @click="saveAccount">提交</el-button>
							</el-form-item> -->
						</el-form>
					</el-col>
				</el-row>
			</a-modal>
		</div>
	</div>
</template>
<script setup>
import { h } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
</script>
<script>
export default {
	created() {
		this.getInit();
	},
	data() {
		return {
			size: 'meduim',
			model_simple_cloud_admin_account: {},
			dataList: [],
			page: { pageSize: 6, startPage: 1, totalResult: 0 },
			accountDialogShow: false,
			currentRow: {},
			editType: '',
			loadingData: false,
			currentColumns: [],
			accountInfo: '',
			columns: [
				{ title: '账户名', dataIndex: 'accountName', width: 200, align: 'center' },
				{ title: '手机号', dataIndex: 'mobile', width: 100, align: 'center' },
				{
					title: '公司名称',
					dataIndex: 'companyName',
					width: 100,
					align: 'center'
				},
				{
					title: '公司logo',
					dataIndex: 'companyLogo',
					width: 100,
					align: 'center'
				},
				{
					title: '头像',
					dataIndex: 'imgUrl',
					width: 100,
					align: 'center'
				},
				{
					title: '操作',
					dataIndex: 'action',
					width: 150,
					align: 'center'
				}
			],
			pagination: {
				showQuickJumper: true,
				pageSizeOptions: [3, 6, 9, 12, 15, 20, 30]
			},
			pColumns: [
				{
					name: 'oldPassword',
					title: '旧密码',
					desc: '请输入旧密码',
					type: 'password'
				},
				{
					name: 'password',
					title: '新密码',
					type: 'password',
					desc: '请输入6位数字或字母组成的密码'
				}
			],
			sColumns: [
				{
					name: 'password',
					title: '新密码',
					type: 'password',
					desc: '请输入6位数字或字母组成的密码'
				}
			],
			totalColumns: [
				{
					name: 'img_url',
					title: '头像',
					fileListName: 'imgUrlList',
					type: 'upload'
				},
				{
					name: 'account_name',
					title: '账户名',
					isNull: 1
				},
				{
					name: 'mobile',
					title: '手机号',
					isNull: 1
				},
				{
					name: 'password',
					type: 'password',
					title: '密码',
					desc: '请输入6位数字或字母组成的密码'
				},
				{
					name: 'company_name',
					title: '公司名'
				},
				{
					name: 'company_logo',
					fileListName: 'companyLogoList',
					title: '公司logo',
					type: 'upload'
				}
			],
			totalInfoColumns: [
				{
					name: 'imgUrl',
					title: '头像',
					fileListName: 'imgUrlList',
					type: 'upload'
				},
				{
					name: 'accountName',
					title: '账户名',
					isNull: 1
				},
				{
					name: 'mobile',
					title: '手机号',
					isNull: 1
				},
				{
					name: 'companyName',
					title: '公司名'
				},
				{
					name: 'companyLogo',
					fileListName: 'companyLogoList',
					title: '公司logo',
					type: 'upload'
				}
			]
		};
	},
	methods: {
		getInit() {
			if (window.localStorage.kaola_accountInfo) {
				this.accountInfo = JSON.parse(window.localStorage.kaola_accountInfo);
			}
			//初始化
			this.getMyAccount();
		},
		async getMyAccount() {
			let data = {};
			let params = {};
			params.page = this.page;
			this.loadingData = true;
			data.params = JSON.stringify(params);
			this.loadingData = false;
			const res = await this.withLoading(this.apiAll(data, '/getMyAccount'));
			if (res.code >= 0) {
				this.dataList = res.extra;
			} else {
				this.errorMsgBox(res.message);
			}
		},
		handleRemove(f, fileList) {
			fileList.some((element, index) => {
				if (element.name == f.name) {
					fileList.splice(index, 1);
				}
			});
		},
		openEdit(row, editType) {
			this.accountDialogShow = true;
			if (row) {
				this.currentRow = row;
			}
			this.editType = editType;
			if (editType == 1) {
				this.currentRow.imgUrlList = [];
				this.currentRow.companyLogoList = [];
				this.currentColumns = this.totalInfoColumns;
			} else if (editType == 0) {
				this.currentColumns = this.pColumns;
			} else if (editType == 2) {
				//新增账户
				this.currentRow = {};
				this.currentColumns = this.totalColumns;
			} else if (editType == 3) {
				//重置密码
				this.currentColumns = this.sColumns;
			}
		},
		//保存用户信息
		async saveAccount() {
			const formData = new FormData();
			if (this.editType == 1) {
				if (this.currentRow.companyLogoList && this.currentRow.companyLogoList.length > 0) {
					formData.append('companyLogof', this.currentRow.companyLogoList[0].raw);
				}
				if (this.currentRow.imgUrlList && this.currentRow.imgUrlList.length > 0) {
					formData.append('imgUrlf', this.currentRow.imgUrlList[0].raw);
				}
				let params = {};
				params.ephemeralParam = this.currentRow.id;
				params.ephemeralParam1 = this.currentRow;
				formData.append('params', JSON.stringify(params));
				const res = await this.withLoading(this.formDataApi(formData, '/saveAccount'));
				if (res.code >= 0) {
					this.successMsgBox(res.message);
					//保存成功更换accountInfo缓存
					this.getInit();
					this.accountDialogShow = false;
				} else {
					this.errorMsgBox(res.message);
				}
			} else if (this.editType == 0) {
				//修改密码
				let data = {};
				data.id = this.currentRow.id;
				data.password = this.currentRow.password;
				data.oldPassword = this.currentRow.oldPassword;
				const res = await this.withLoading(this.apiAll(data, '/changePassword'));
				if (res.code >= 0) {
					this.successMsgBox(res.message);
					this.getInit();
					this.accountDialogShow = false;
				} else {
					this.errorMsgBox(res.message);
				}
			} else {
				let params = {};
				params.ephemeralParam = this.currentRow.id;
				params.ephemeralParam1 = this.currentRow;
				let data = {};
				data.params = JSON.stringify(params);
				const res = await this.withLoading(this.apiAll(data, '/saveAccount'));
				if (res.code >= 0) {
					this.successMsgBox(res.message);
					this.getInit();
					this.accountDialogShow = false;
				} else {
					this.errorMsgBox(res.message);
				}
			}
		},
		searchData() {
			alert('这里是搜索！');
		},
		clearSearch() {
			alert('清除所有搜索条件！');
		},
		handleSelectionChange(val) {
			alert('选中数据！');
		},
		sizeChange(val) {
			this.page.pageSize = val;
		},
		currentChange(val) {
			this.page.pageSize = val;
		}
	}
};
</script>
<style lang="less" scoped></style>
