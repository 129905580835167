<template>
	<div class="wx-set">
		<el-card>
			<div style="display: flex; justify-content: space-between">
				<el-radio-group v-model="model.allow_pay" style="margin-bottom: 20px">
					<el-radio value="开启支付" :label="1">开启支付</el-radio>
					<el-radio value="关闭支付" :label="0">关闭支付</el-radio>
				</el-radio-group>
				<!-- <el-button type="primary" icon="check" :size="size" @click="savePaySet"
          >保存</el-button
        > -->

				<a-button type="primary" :icon="h(CheckOutlined)" :size="size" @click="savePaySet">保存</a-button>
			</div>
			<el-divider>支付配置</el-divider>

			<div v-if="model.allow_pay == 1">
				<el-form-item label="支付类型：" style="margin-bottom: 20px">
					<el-checkbox-group v-model="model.pay_way">
						<el-checkbox value="online_pay" label="online_pay">线上支付</el-checkbox>
						<el-checkbox value="offline_pay" label="offline_pay" @change="offlinepayChange">线下支付</el-checkbox>
					</el-checkbox-group>
				</el-form-item>

				<el-form-item label="支付方式：" style="margin: 0px 0 30px 0">
					<el-checkbox-group v-model="model.onlinepays" style="margin-right: 20px">
						<el-checkbox value="wx_pay" label="wx_pay" v-if="hasOnlinePay">
							<img :src="wxPayImg" style="width: 40px; height: 40px; display: block; margin-bottom: 10px" />
							<span style="font-size: 12px">微信</span>
						</el-checkbox>
						<el-checkbox value="ali_pay" label="ali_pay" v-if="hasOnlinePay">
							<img :src="alipayImg" style="width: 40px; height: 40px; display: block; margin-bottom: 10px" />
							<span style="font-size: 12px">支付宝</span>
						</el-checkbox>
						<el-checkbox value="offline_pay" label="offline_pay" v-if="hasOfflinePay">
							<img :src="offlinepayImg" style="width: 40px; height: 40px; display: block; margin-bottom: 10px" />
							<span style="font-size: 12px">线下</span>
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-divider>支付参数设置</el-divider>

				<el-form label-position="top" label-width="200px">
					<el-form-item label="">
						<el-radio-group v-model="currentPayWay">
							<el-radio
								:value="i == 'ali_pay' ? '支付宝支付' : i == 'wx_pay' ? '微信支付' : '线下支付'"
								:label="i == 'ali_pay' ? '支付宝支付' : i == 'wx_pay' ? '微信支付' : '线下支付'"
								v-for="(i, index) in model.onlinepays"
								:key="index"
							></el-radio>

							<!-- <el-radio
                v-if="hasOfflinePay"
                value="线下支付"
                label="线下支付"
              ></el-radio> -->
						</el-radio-group>
					</el-form-item>
				</el-form>

				<!-- 选中了微信支付才进行配置 -->
				<el-form label-width="100px" v-if="currentPayWay == '微信支付'">
					<el-form-item label="appid：">
						<el-input v-model="model.wx_set.appid"></el-input>
					</el-form-item>
					<el-form-item label="密钥：">
						<el-input v-model="model.wx_set.secret"></el-input>
					</el-form-item>
					<el-form-item label="商户私钥：">
						<el-input v-model="model.wx_set.privatekey" type="textarea" rows="8"></el-input>
					</el-form-item>
					<el-form-item label="商户号：">
						<el-input v-model="model.wx_set.mchId"></el-input>
					</el-form-item>
					<el-form-item label="商户流水号：">
						<el-input v-model="model.wx_set.mchSerialNo"></el-input>
					</el-form-item>
					<el-form-item label="apiV3密钥：">
						<el-input v-model="model.wx_set.apiV3Key"></el-input>
					</el-form-item>
				</el-form>

				<el-form label-width="100px" v-else-if="currentPayWay == '线下支付'">
					<el-form-item label="公司名称：">
						<el-input v-model="model.offline_set.company_name" placeholder="公司名称"></el-input>
					</el-form-item>
					<el-form-item label="开户行：">
						<el-input v-model="model.offline_set.bank" placeholder="如工商银行、建设银行等"></el-input>
					</el-form-item>
					<el-form-item label="银行账号：">
						<el-input v-model="model.offline_set.bank_no" placeholder="银行账号"></el-input>
					</el-form-item>
					<el-form-item label="转账须知：">
						<el-input placeholder="转账截止日期，转账须知等" v-model="model.offline_set.info" :rows="10" type="textarea"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>
<script setup>
import { h } from 'vue';
import { CheckOutlined } from '@ant-design/icons-vue';
</script>
<script>
export default {
	setup() {},
	computed: {
		hasOfflinePay() {
			return this.model.pay_way.some((element) => {
				return element == 'offline_pay';
			});
		},
		hasOnlinePay: function () {
			return this.model.pay_way.some((element) => {
				return element == 'online_pay';
			});
		}
	},
	data() {
		return {
			model: {
				allow_pay: 0,
				pay_way: [],
				onlinepays: [],
				wx_set: {},
				alipay_set: {},
				offline_set: { bank: '', bank_no: '', info: '' }
			},
			size: 'large',
			containWxPay: false,
			currentPayWay: '',
			user_project_types: [],
			userAddProjectTypes: [],
			alipayImg: 'https://koalahuiyi.com/resources/imgs/common/alipay.png',
			wxPayImg: 'https://koalahuiyi.com/resources/imgs/common/wxpay.png',
			offlinepayImg: 'https://koalahuiyi.com/resources/imgs/common/offlinepay.png'
		};
	},
	created() {
		this.getInit();
	},
	methods: {
		getInit() {
			this.getPaySet();
		},
		offlinepayChange(val) {
			if (val) {
				this.model.onlinepays.push('offline_pay');
			} else {
				this.model.onlinepays.forEach((element, index) => {
					if (element == 'offline_pay') {
						this.model.onlinepays.splice(index, 1);
					}
				});
			}
		},
		async getPaySet() {
			let params = {};
			let data = {};
			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/getPaySet'));
			if (res.code >= 0) {
				if (res.extra) {
					this.model = res.extra;
				}
			} else {
				this.errorMsgBox(res.message);
			}
		},

		async savePaySet() {
			console.log(JSON.stringify(this.model));
			let params = {};
			let data = {};
			params.ephemeralParam = this.model;

			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/savePaySet'));
			if (res.code >= 0) {
				this.successMsgBox(res.message);
			} else {
				this.errorMsgBox(res.message);
			}
		}
	}
};
</script>
<style scoped lang="less">
.wx-set {
	width: 60%;
	padding: 20px;
	/deep/ .el-form--default.el-form--label-top .el-form-item .el-form-item__label {
		font-weight: bold;
		font-size: 15px;
	}
	/deep/ .el-checkbox__label {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>