<template>
	<div class="sms-set">
		<el-row style="padding: 10px">
			<el-col :span="18">
				<el-form-item label="" label-width="170px">
					<span style="font-size: 18px">说明：打开或关闭短信系统通知选项，当前系统通知短信状态为：</span>
					<span style="color: green; font-size: 20px" v-if="accountInfo.smsCloseStatus && accountInfo.smsCloseStatus == 1">开启</span>
					<span style="color: red; font-size: 20px" v-else>关闭</span>
				</el-form-item>
			</el-col>

			<el-col style="text-align: right" :span="6">
				<!-- <el-button
          type="primary"
          size="large"
          icon="select"
          @click="saveSet"
          :disabled="
            !accountInfo.smsCloseStatus || accountInfo.smsCloseStatus !== 1
          "
          >保存</el-button
        > -->

				<a-button type="primary" @click="saveSet" :disabled="!accountInfo.smsCloseStatus || accountInfo.smsCloseStatus !== 1">
					保存
					<template #icon>
						<CheckOutlined />
					</template>
				</a-button>
			</el-col>
			<el-col style="text-align: right" :span="24">
				<el-form-item label="" label-width="170px">
					<el-tag
						:disabled="!accountInfo.smsCloseStatus || accountInfo.smsCloseStatus !== 1"
						@click="addSmsField(i)"
						style="margin: 5px 10px 0 0; padding: 16px; font-size: 16px"
						v-for="(i, index) in registFields"
						:key="index"
						:type="template ? 'primary' : 'info'"
					>
						{{ i }}
					</el-tag>
				</el-form-item>
			</el-col>
		</el-row>
		<el-form :style="{ maxHeight: maxHeight, overflow: 'auto' }">
			<!-- <el-form-item label="" label-width="170px">
        <el-button type="primary" size="large" icon="select" @click="saveSet"
          >保存</el-button
        >
      </el-form-item> -->

			<el-form-item :label="`${i.name}：`" label-width="170px" v-for="(i, index) in form" :key="index">
				<!--           @click="saveHuiyiSmsSetCloseStatus(i.close_status, i.id)"坑爹的首次初始化

      -->

				<el-input v-model="i.content" v-if="i.name == '短信标签'" />

				<div v-else style="width: 100%">
					<el-switch
						@change="saveHuiyiSmsSetCloseStatus($event, i.id)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						v-model="i.close_status"
						size="large"
						:active-value="1"
						:inactive-value="0"
						:disabled="!accountInfo.smsCloseStatus || accountInfo.smsCloseStatus !== 1"
					/>
					<el-input v-model="i.content" type="textarea" rows="4" :maxlength="350" show-word-limit @focus="setCurrentTemplate(i)"></el-input>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
export default {
	setup() {},
	data() {
		return {
			form: [],
			registFields: ['姓名', '手机号', '二维码图片', '二维码链接'],
			template: '',
			maxHeight: '',
			accountInfo: ''
		};
	},
	created() {
		//this.getInit();
		this.maxHeight = window.innerHeight - 230 + 'px';
		this.accountInfo = JSON.parse(window.localStorage.kaola_accountInfo);
		this.getSmsSet();
	},
	methods: {
		async getSmsSet() {
			let data = {};
			let params = {};
			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/getSmsSetForAdmin'));
			if (res.code >= 0) {
				this.form = res.extra;
			} else {
				this.errorMsgBox(res.message);
			}
		},
		setCurrentTemplate(i) {
			this.template = i;
		},
		addSmsField(fieldMappingName) {
			if (this.template) {
				this.template.content = this.template.content + '{' + fieldMappingName + '}';
			}
		},
		async saveHuiyiSmsSetCloseStatus(closeStatus, id) {
			let data = {};
			let params = {};
			params.ephemeralParam = id;
			params.ephemeralParam1 = closeStatus;

			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/saveSmsSetCloseStatus'));
			if (res.code >= 0) {
				window.sessionStorage.setItem('systemSmsData', JSON.stringify(this.form));
				if (closeStatus == 1) {
					this.successMsgBox('开启成功！');
				} else {
					this.successMsgBox('关闭成功！');
				}
			} else {
				this.errorMsgBox(res.message);
			}
		},
		async saveSet() {
			let data = {};
			let params = {};
			params.mapEphemeralParamList = this.form;
			data.params = JSON.stringify(params);
			const res = await this.withLoading(this.apiAll(data, '/saveSmsSet'));
			if (res.code >= 0) {
				//重新获取一下
				this.getSmsSet();
				this.successMsgBox(res.message);
			} else {
				this.errorMsgBox(res.message);
			}
		}
	}
};
</script>
<style scoped>
.sms-set {
	width: 70%;
	padding: 10px;
}
</style>
