<template>
	<div style="padding: 30px">
		<el-row :gutter="10">
			<!-- <el-col :span="5">
        <el-card>
          <el-row>
            <el-col style="text-align: center">
              <img
                :src="form.companyLogo"
                style="border-radius: 50% width:80px;height:80px"
              />
            </el-col>

            <el-col style="text-align: center; height: 50px; line-height: 50px">
              {{ form.companyName }}
            </el-col>
            <el-col style="text-align: center">
              {{ form.info ? form.info : "暂无简介" }}
            </el-col>
          </el-row>
        </el-card>
      </el-col> -->
			<el-col
				:span="16"
				:style="{
					width: '100%'
				}"
			>
				<div style="background: #fff; position: relative; maxheight: calc(100vh - 160px); overflow: auto">
					<div style="position: absolute; right: 20px; top: 20px">
						<a-button type="primary" :icon="h(CheckOutlined)" :size="size" @click="saveAccount">保存</a-button>
					</div>
					<el-form ref="form" :model="form" label-width="180px" style="margin-top: 40px">
						<!-- <el-form-item label="手机号" required>
              <el-input v-model="form.mobile"></el-input>
            </el-form-item> -->

						<el-form-item label="账户头像">
							<el-row :style="{ width: '100%' }">
								<el-col :span="8" v-if="form.img_url">
									<img :src="form.img_url" style="height: 100px" />
								</el-col>
								<el-col :span="16">
									<el-upload
										:on-remove="handleRemove"
										action="#"
										limit="1"
										v-model:file-list="form.imgUrlList"
										:on-change="handleUpload"
										:auto-upload="false"
										:before-upload="beforeAvatarUpload"
									>
										<el-icon><Plus /></el-icon>
									</el-upload>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="账户名称" required>
							<el-input style="width:60%;" v-model="form.account_name"></el-input>
						</el-form-item>
						<el-form-item label="姓名" required>
							<el-input style="width:60%;" v-model="form.user_name"></el-input>
						</el-form-item>
						<el-form-item label="手机号" required>
							<el-input style="width:60%;" v-model="form.mobile"></el-input>
						</el-form-item>
						<el-form-item label="公司名称" required>
							<el-input style="width:60%;" v-model="form.company_name"></el-input>
						</el-form-item>
						<el-form-item label="公司logo">
							<el-row :style="{ width: '100%' }">
								<el-col :span="8" v-if="form.company_logo">
									<img :src="form.company_logo" style="height: 120px" />
								</el-col>
								<el-col :span="16">
									<el-upload
								
										:on-remove="handleRemove"
										action="#"
										limit="1"
										v-model:file-list="form.companyLogoList"
										:on-change="handleUpload"
										:auto-upload="false"
										:before-upload="beforeAvatarUpload"
									>
										<el-icon><Plus /></el-icon>
									</el-upload>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="公司简介">
							<el-input style="width:60%;" placeholder="公司简介" type="textarea" :rows="2" v-model="form.info"></el-input>
						</el-form-item>
						<!-- <el-form-item label="公司地址">
							<el-cascader class="dateInput" size="large" :options="optionsAddress" v-model="form.address" @change="handleChange"></el-cascader>
						</el-form-item> -->

						<!-- <el-form-item label="短信权限">
              <el-switch
                disabled
                v-model="form.smsCloseStatus"
                active-value="1"
                inactive-value="0"
                active-text="正常"
                inactive-text="关闭"
              ></el-switch>
            </el-form-item> -->
						<el-form-item label="单场活动最大注册人数" required>
							<el-input style="width:60%;" v-model="form.registNum" disabled></el-input>
						</el-form-item>
						<el-form-item label="短信余额">
							<el-input style="width:60%;"  v-model="form.smsNum" disabled :size="size"></el-input>
							<a-button :icon="h(ShoppingCartOutlined)" style="margin-left: 5%" type="primary" :size="size" @click="addSmsNum">充值</a-button>
						</el-form-item>
						<el-form-item label="邮件余额">
							<a-input style="width:60%;"  v-model="form.emailNum" disabled :size="size"></a-input>
							<a-button :icon="h(ShoppingCartOutlined)" style="margin-left: 5%" type="primary" :size="size" @click="addEmailNum">充值</a-button>
						</el-form-item>
					</el-form>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script setup>
import { h } from 'vue';
import { CheckOutlined,ShoppingCartOutlined } from '@ant-design/icons-vue';
</script>
<script>
export default {
	setup() {},
	data() {
		return {
			size: 'large',
			form: {},
			backgroundImgList: []
		};
	},
	created() {
		this.getInit();
	},
	methods: {
		getInit() {
			this.form = JSON.parse(localStorage.getItem('kaola_accountInfo'));
		},

		//保存用户信息
		async saveAccount() {
			const formData = new FormData();

			if (this.form.companyLogoList && this.form.companyLogoList.length > 0) {
				formData.append('companyLogof', this.form.companyLogoList[0].raw);
			}
			if (this.form.imgUrlList && this.form.imgUrlList.length > 0) {
				formData.append('imgUrlf', this.form.imgUrlList[0].raw);
			}
			let params = {};
			params.ephemeralParam = this.form.id;
			params.ephemeralParam1 = this.form;
			formData.append('params', JSON.stringify(params));
			const res = await this.withLoading(this.formDataApi(formData, '/saveAccount'));
			if (res.code >= 0) {
				//将地址返回更新
				this.successMsgBox(res.message);
				localStorage.setItem('accountInfo', JSON.stringify(this.form));
			} else {
				this.errorMsgBox(res.message);
			}
		}
	}
};
</script>
